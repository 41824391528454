.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.badge
{
  color: #000;
}
.list-group-item{
  padding: 5px;
  font-size: .75em;
    font-weight: normal;
    line-height: 1;
}

.table th {
  text-transform: uppercase;

}
.footerfilterheder {
background-color: #eee;
text-transform: uppercase;
font-weight: 700;

}
.headercount li{
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;

}
.pagination {
  display: flex;
/*  justify-content: space-between;*/
  list-style: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  padding-bottom: 20px!important;
  border: none!important;
  
 }
 
 .pagination a {
  cursor: default;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #6c7ac9;
  color: #6c7ac9;
  margin-left: 10px;
 }
 
 .pagination li:not(.disabled) a:hover {
  background-color: bisque;
  cursor: pointer;
 }
 
 .pagination li a {
  font-weight: bold;
 }
 
 .pagination li.active a {
  color: #fff;
  background: #6c7ac9;
 }
 
 .pagination li.disabled a {
  pointer-events: none;
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
 }
 .tab-content{
   margin-top: 10px;
   width: 100%;

 }
 .mandatory
 {
   color: red;
 }
 .bannerimg{
   width: 100%;
   height: auto;

 }
 /* start for Susbscription Page */
 .sec_pad {
  padding: 80px 0;
}

.sec_heading {
  font-weight: 700;
  word-break: break-word;
  overflow-wrap: break-word;
}

.subscription_box {
  background: #fff;
  position: relative;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
  transition: .3s all;
}

  .subscription_box:hover {
      margin-top: -20px;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07);
  }

  .subscription_box h4 {
      font-size: 25px;
      transition: .3s all;
      font-weight: 600;
      text-align: center;
      padding: 20px 10px;
      background: #7b7b7b;
      color: #fff;
  }

  .subscription_box:hover h4 {
      padding-top: 40px;
  }

  .subscription_box h5 {
      color: #7b7b7b;
      padding-top: 25px;
      font-size: 25px;
      font-weight: 900;
  }

  .subscription_box p {
      color: #ACBCFF;
      font-size: 21px;
      margin-top: 10px;
      margin-bottom: 0;
  }

  .subscription_box.executive h4 {
      background: #1E43DF;
  }

  .subscription_box.executive h5, .subscription_box.executive p {
      color: #1E43DF;
  }

  .subscription_box.business h4 {
      background: #FDBA28;
  }

  .subscription_box.business h5, .subscription_box.business p {
      color: #FDBA28;
  }

  .subscription_box.business h5 {
      padding-top: 10px;
  }

  .subscription_box.corporate h4 {
      background: #FE653C;
  }

  .subscription_box.corporate h5, .subscription_box.corporate p {
      color: #FE653C;
  }

.recomm_plan {
  position: relative;
  background: #FEDC93;
  padding-bottom:30px;
}

  .recomm_plan span {
      position: absolute;
      font-weight: 500;
      bottom: 6px;
      left: 0;
      width: 100%;
      color: #fff;
      font-size: 20px;
      text-align: center;
      text-shadow: 0px 2px 5px #0e0e0e9c;
  }

.subscription_box ul {
  margin: 0;
  padding: 0;
}

  .subscription_box ul li {
      list-style: none;
      font-weight: 600;
      font-size: 20px;
      border-top: 2px solid #F2F2F2;
      padding: 10px 0;
  }

      .subscription_box ul li p {
          margin: 0;
          color: #505050 !important;
          font-size: 20px;
          font-weight: normal;
      }

.subscription_box .blue_btn {
  width: 150px;
  background: #fff;
  color: #1E43DF;
  margin: 15px 0;
  text-decoration: none;
}

  .subscription_box .blue_btn:hover, .subscription_box.business .blue_btn {
      background: #1E43DF;
      color: #fff;
  }

.subscription_box ul li:last-child {
  border-bottom: 2px solid #F2F2F2;
}

.plan_featbox {
  color: #fff;
  padding: 10px;
}

  .plan_featbox.first {
      background: #FE653C;
  }

  .plan_featbox.second {
      background: #FDBA28;
  }

  .plan_featbox.third {
      background: #1E43DF;
  }

  .plan_featbox h6 {
      padding: 10px;
      background: #fff;
      color: #08080A;
  }

  .plan_featbox ul {
      margin: 0;
      padding-top: 0;
  }

      .plan_featbox ul li {
          list-style: none;
          margin-left: 23px;
          line-height: 28px;
      }

.paln_featnote ul {
  margin: 0;
}

  .paln_featnote ul li {
      list-style: none;
      padding: 5px 0;
      font-size: 13px;
      margin-left: 20px;
      color: #707070;
  }

.payment_block h6 {
  color: #1E43DF;
  font-weight: 600;
}

.payment_block p strong {
  font-weight: 600;
  display: block;
  margin-bottom: 10px;
}

.payment_block ul {
  margin: 0;
}

  .payment_block ul li {
      list-style: none;
      line-height: 28px;
  }

      .payment_block ul li span {
          font-weight: 600;
          width: 150px;
          display: inline-block;
      }

.faq_box {
  margin-top: 30px;
}

.faqclick {
  display: flex;
  align-items: center;
  cursor: pointer;
}

  .faqclick h5 {
      margin: 0;
  }

  .faqclick i {
      margin-left: auto;
      transition: .3s ease-in-out;
  }

.faq_detail {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0 #00000030;
  padding: 20px;
  margin-top: 20px;
  display: none;
}

.faq_box:first-child .faq_detail {
  display: block;
}

.faq_box.active .faqclick i {
  transform: rotate(180deg);
}

.faq_detail h6 {
  color: #1E43DF;
  position: relative;
  margin-bottom: 15px;
}

.faq_detail p, .faq_detail ul li {
  margin-bottom: 0;
  color: #08080A;
  line-height: 28px;
}

.faq_detail .faw_detbox + .faw_detbox {
  border-top: 1px dashed #E8E8E9;
  padding-top: 30px;
  margin-top: 30px;
}

.cont_psec .white_box.bg-light {
  background: #fff !important;
}
.bg-light {
  background-color: #f8f9fa!important;
}
.bg-white {
  background-color: #fff!important;
}
.lblue_sec {
  background: #ECF0FF;
}

.sec_headLineh {
  line-height: 38px;
}

.text_blue {
  color: #1E43DF;
}

.text_yellow {
  color: #FDBA28;
}

.text_grey {
  color: #505050;
}

.sec_text {
  font-weight: 500;
  line-height: 30px;
}

.btn_wthlnk .blue_btn {
  padding: 10px 40px;
  margin-right: 10px;
}

.linknor {
  color: #1E43DF;
  transition: .3s ease-in-out;
  font-weight: 500;
}

  .linknor:hover {
      color: #FDBA28;
      text-decoration: none;
  }
  
header {
  transition: .2s ease-in-out;
}

.main_logo img {
  width: 210px;
}

header.sticky_header {
  position: fixed;
  top: 0;
  border: 0;
  z-index: 9;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.11);
}
header ul {
  margin: 0;
  text-align: right;
}

  header ul li {
      display: inline-block;
      list-style: none;
      position: relative;
  }

      header ul li a {
          color: #08080A;
          font-weight: 600;
          padding: 25px 10px;
          display: block;
          transition: .3s ease-in-out;
          position: relative;
          text-decoration: none;
      }

          header ul li a.h_nav {
              color: #1E43DF;
              font-weight: 900;
          }

          header ul li a:hover {
              /* text-decoration: underline; */
              color: #FDBA28;
          }

          header ul li a::before {
              content: '';
              position: absolute;
              top: 0;
              border-radius: 100px;
              left: 50%;
              opacity: 0;
              transform: translate(-50%, 0);
              height: 50px;
              width: 50px;
              transition: .3s ease-in-out;
              z-index: 9;
              background: #FDBA28;
          }

          header ul li a::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translate(-50%, 0);
              height: 2px;
              width: 2px;
              transition: .3s ease-in-out;
              z-index: 9;
              background: #FDBA28;
              opacity: 0;
          }

          header ul li a:hover::before, header ul li.drop_down:hover > a::before {
              height: 0px;
              width: 2px;
              border-radius: 0;
              top: 100%;
              opacity: 1;
          }
          header ul li a.h_nav::after {
              background: #1E43DF;
              width: 100%;
              opacity: 1;
          }
          header ul li a:hover::after, header ul li.drop_down:hover > a::after, header ul li a.h_nav:hover::after {
              width: 100%;
              background:#FDBA28 !important;
              opacity: 1;
          }

      header ul li ul {
          position: absolute;
          width: 200px;
          top: 100%;
          background: #fff;
          text-align: left;
          transition: .3s ease-in-out;
          opacity: 0;
          z-index: 9;
          pointer-events: none;
          transform: rotate3d(1, 1, 25, 90deg);
      }

      header ul li:hover ul {
          top: 100%;
          pointer-events: all;
          transform: none;
          opacity: 1;
      }

      header ul li.drop_down:hover > a {
          color: #FDBA28;
      }

      header ul li ul li {
          display: block;
      }

          header ul li ul li a, header.sticky_header ul li ul li a {
              color: #08080A;
              padding: 10px;
          }
          