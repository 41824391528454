*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #08080A;
    background: #ECF0FF;
}
a{
    text-decoration: none;
}
ol, ul{
    padding-left: 0px;
}
.bg-white {
    background: #fff !important;
}
.ec_shadow {
    box-shadow: 0 12px 20px 0 rgb(0 0 0 / 10%);
}
.topheader{
    background: #08080A;
}
.topcontact, .toplogin {
    margin: 0;
    padding: 0;
}
.toplogin{
    text-align: right;
}
.topcontact li, .toplogin li{
    display: inline-block;
    list-style: none;
    padding:5px 10px;
}
.topcontact li:first-child, .toplogin li:first-child{
    padding-left: 0;
}
.topcontact li:last-child, .toplogin li:last-child{
    padding-right: 0;
}
.topcontact li a, .toplogin li a{
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}
.topcontact li a:hover, .toplogin li a:hover{
    text-decoration: none;
    color: #ecf0ff;
}
header{
    background: #fff;
    /* padding: 10px 0; */
    box-shadow: 0 5px 5px 0 #ddd;
    position: relative;
}
.brand_logo{
    display: block;
    width: 195px;
}
.brand_logo img{
    max-width: 100%;
}
.header_btn {
    margin: 0;
    padding: 0;
    text-align: right;
}
.header_btn li{
    list-style: none;
    display: inline-block;
    margin: 0 5px;
}
.header_btn li:first-child{
    margin-left: 0;
}
.header_btn li:last-child{
    margin-right: 0;
}
.header_btn li a{
    display: inline-block;
    border: 1px solid #1E43DF;
    color: #1E43DF;
    border-radius: 5px;
    padding: 10px;
    font-weight: 500;
    transition: .3s all;
}
.header_btn li a:hover{
    text-decoration: none;
    transition: .3s all;
    background: #1E43DF;
    color:#fff;
}
.header_btn li:last-child a{
    background: #1E43DF;
    color:#fff;
}
.header_btn li:last-child a:hover{
    background: #fff;
    color:#1E43DF;
}
.sec_padding{
    padding: 35px 0;
}
.main_heading{
    font-size: 32px;
    font-weight: 700;
}
h2.common_heading{
    font-size: 20px;
    font-weight: 700;
}
h3.common_heading{
    font-size: 24px;
    font-weight: 700;
}
.common_tagline{
    font-size: 18px;
}
.subscription_box{
    background: #fff;
    position: relative;
    text-align: center;
    box-shadow: 0 0 5px 0 #00000030;
    margin-top: 20px;
    transition: .3s all;
}
.subscription_box:hover{
    margin-top: 0;
    transition: .3s all;
    box-shadow: 0 0 20px 0 #00000030;
}
.subscription_box:hover h3{
    padding-top: 40px;
    transition: .3s all;
}
.subscription_sec .row:last-child{
    margin-top: 20px;
}
.subscription_box h3{
    font-size: 25px;
    transition: .3s all;
    font-weight: 600;
    text-align: center;
    padding:20px 10px;
    padding-bottom: 35px;
    background: #ACBCFF;
    color: #fff;
    -webkit-clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%);
clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 100%);
}
.subscription_sec .col-lg-3:nth-child(2) .subscription_box h3{
    background: #4467FE;
}
.subscription_sec .col-lg-3:nth-child(3) .subscription_box h3{
    background: #FDBA28;
    padding-top: 40px;
}
.subscription_sec .col-lg-3:nth-child(3) .subscription_box{
    margin-top: 0;
}
.subscription_sec .col-lg-3:last-child .subscription_box h3{
    background: #FE653C;
}
.recomm_plan{
    position: relative;
    background: #FEDC93;
    padding-bottom: 15px;
}
.recomm_plan span{
    position: absolute;
    font-weight: normal;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    font-size: 20px;
    text-align: center;
}
.subscription_box h4{
    /* color: #ACBCFF; */
    font-size: 30px;
    font-weight: 900;
    padding-top: 25px;
}
.subscription_box p{
    color: #ACBCFF;
    font-size: 25px;
}
.subscription_box .blue_btn {
    width: 150px;
    background: #fff;
    color: #1e43df;
    margin: 15px 0;
}
.blue_btn {
    background: #1e43df;
    padding: 10px 12px;
    display: inline-block;
    border-radius: 5px;
    font-weight: 500;
    color: #fff;
    border: 2px solid #1e43df;
    transition: .3s ease-in-out;
}
.subscription_box .blue_btn:hover, .subscription_box.business .blue_btn {
    background: #1e43df;
    color: #fff;
}
.subscription_sec .col-lg-3:nth-child(2) .subscription_box h4,
.subscription_sec .col-lg-3:nth-child(2) .subscription_box p{
    color: #4467FE;
}
.subscription_sec .col-lg-3:nth-child(3) .subscription_box h4{
    color: #FDBA28;
    padding-top: 10px;
}
.subscription_sec .col-lg-3:last-child .subscription_box h4,
.subscription_sec .col-lg-3:last-child .subscription_box p{
    color: #FE653C;
    
}
.subscription_sec .col-lg-3:nth-child(3) .subscription_box p{
    color: #FDBA28;
}
.subscription_box ul{
    margin: 0;
    padding: 0;
}
.subscription_box ul li{
    list-style: none;
    font-weight: 600;
    font-size: 22px;
    border-top:2px solid #F2F2F2;
    padding: 10px 0;
}
.subscription_box ul li:last-child{
    border-bottom:2px solid #F2F2F2;
}
.subscription_box ul li p{
    margin: 0;
    color: #92949E !important;
    font-size: 20px;
    font-weight: normal;
}
.subscription_box .btn_default{
    margin: 15px 0;
    margin-top: 30px;
    padding: 5px 50px;
    font-size: 20px;
}
.btn_default{
    border: 2px solid #1E43DF;
    color: #1E43DF;
    border-radius: 50px;
    padding: 5px 10px;
    font-weight: 500;
    display: inline-block;
    transition: .3s all;
    cursor: pointer;
    background: transparent;
}
.btn_default2{
    background: #1E43DF;
    color: #fff;
}
.btn_default:hover{
    background: #1E43DF;
    color: #fff;
    transition: .3s all;
    text-decoration: none;
}
.btn_default2:hover{
    color: #1E43DF;
    background: transparent;
}

.btn_default.noradius{
    border-radius: 5px;
}
.btn_default:active, .btn_default:focus{
    box-shadow: none;
    outline: 0;
}

.grey_bg{
    background: #F2F2F2;
}
.process{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}
.process li{
    list-style: none;
    flex: 0 0 33.3%;
    max-width: 33.3%;
    text-align: center;
    margin-bottom: 60px;
    position: relative;
}
.process li:nth-last-child(-n+3){
    margin-bottom: 0;
}
.process li img{
    width: 100px;
}
.process li p{
    font-size: 20px;
    margin: 0;
    margin-top: 20px;
}
.process li::before{
    content: '';
    position: absolute;
    height: 100%;
    background-repeat: no-repeat;
    right: -268px;
    
   /* background-image: url(images/arrow.png);*/
    top: -20px;
    width: 100%;
    background-size: 50%;
}
.process li:nth-child(2)::before{
    transform: rotateX(180deg);
    top: 20px;
}
.process li:nth-child(3)::before{
    transform: rotateX(180deg) rotate(270deg);
    top: 195px;
    right: -110px;
}
.process li:nth-child(4)::before{
    transform: rotateX(180deg) rotateY(180deg);
    top: 0;
    right: -90px;
}
.process li:nth-child(5)::before{
    transform: rotateY(180deg);
    right: -90px;
    top: -20px;
}
.process li:nth-child(6)::before{
    content: none;
}
.payment_method {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}
.payment_method li{
    list-style: none;
    flex: 0 0 25%;
    max-width: 25%;
}
.pay_methbox{
    text-align: center;
    padding: 30px 0;
    position: relative;
}
.pay_methbox::before{
    content: '';
    position: absolute;
    height: 100%;
    right: 0;
    top: 0;
    width: 2px;
    background: #ECF0FF;
}
.pay_methbox::after{
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 2px;
    background: #ECF0FF;
}
.payment_method li:nth-last-child(-n+4) .pay_methbox::after{
    content: none;
}
.payment_method li:last-child .pay_methbox::before, .payment_method li:nth-child(4) .pay_methbox::before{
    content: none;
}
.pay_methbox span{
    background: #4467FE;
    border-radius: 100%;
    display: inline-block;
    height: 130px;
    width: 130px;
    line-height: 130px;
    position: relative;
    transition: .3s all;
}
.pay_methbox{
    transition: .3s all;
}
.pay_methbox span::before{
    content: '';
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 100px;
    border: 2px solid #4467FE;
    z-index: 999;
    filter: blur(5px);
  -webkit-filter: blur(5px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .3s all;
  z-index: -1;
}
.payment_method li:hover .pay_methbox span::before{
    height: 155px;
    width: 155px;
    transition: .3s all;
    z-index: 1;
}
.pay_methbox span img{
    width: 80px;
}
.payment_method li:last-child .pay_methbox span img{
    width: 100px;
}
.pay_methbox p{
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    margin-top: 20px;
}
.analys_rprt .btn_default{
    padding: 10px;
}
.text_grey{
    color:#92949E;
}
.analys_rprt p{
    margin: 20px 0;
    line-height: 30px;
    font-size: 20px;
    margin-bottom: 40px;
}
.flag_box{
    border-radius: 5px;
    display: flex;

}
.flag_box img{
    width: 215px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.flag_det{
    padding: 10px;
    padding-left: 20px;
}
.flag_det h3{
    font-weight: 700;
}
.flag_det p{
    font-size: 18px;
    margin-bottom: 5px;
}
.viewall{
    color: #1E43DF;
    font-weight: 500;
    font-size: 17px;
    transition: .3s all;
}
.viewall i{
    margin-left: 5px;
    transition: .3s all;
}
.viewall:hover{
    text-decoration: none;
    transition: .3s all;
    color:#1E43DF;
}
.viewall:hover i{
    transition: .3s all;
    margin-left: 15px;
}

.flag_box {
    border-radius: 5px;
    display: flex;
}

    .flag_box img {
        width: 85px;
        border-radius: 5px;
        margin: auto 0;
    }

.flag_det {
    padding: 10px;
    padding-left: 20px;
}

    .flag_det h4 {
        font-weight: 700;
    }

    .flag_det p {
        font-size: 18px;
        margin-bottom: 5px;
    }

.flag_table table th {
    background: #ECF0FF;
    color: #4467FE;
    border-bottom: 2px solid #fff;
    border-top: none;
    vertical-align: middle;
}

.flag_table table tbody tr:last-child th, .flag_table table tbody tr:last-child td {
    border-bottom: 0;
}

.flag_table table td {
    vertical-align: middle;
    background: #fff;
    border-bottom: 2px solid #F2F2F2;
    border-top: none;
    font-weight: 600;
}

    .flag_table table td a {
        color: #1E43DF;
        font-weight: 500;
    }

        .flag_table table td a:hover {
            color: #1E43DF;
        }

.flag_table {
    position: relative;
    box-shadow: 0 0 5px 0 #00000030;
    overflow: hidden;
    margin-top: 40px;
    border-radius: 10px;
}

.flag_table2 {
    transform: skew(0deg, -2deg);
}

.flag_table::before {
    content: '';
    height: 100%;
    width: 35%;
    left: 1px;
    top: 0;
    position: absolute;
    background: #ECF0FF;
    z-index: -1;
}

.flag_table::after {
    content: '';
    height: 100%;
    width: 65%;
    right: 1px;
    top: 0;
    position: absolute;
    background: #fff;
    z-index: -1;
}

.flag_table table {
    margin: 0;
}

.flag_table2 table {
    transform: skew(0deg, 2deg);
}

.impot_tabw_flag {
    background: #f2f2f2;
    padding: 10px;
    padding-top: 5px;
    border-radius: 5px;
}

    .impot_tabw_flag .tag_budget li a {
        background: #fff;
    }

        .impot_tabw_flag .tag_budget li a.active, .impot_tabw_flag .tag_budget li a:hover {
            background: #FDBA28;
        }

.impot_flags {
    background: #fff;
    margin: 0;
    border-radius: 5px;
    margin-top: 5px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
}

    .impot_flags li {
        flex: 0 0 20%;
        border-bottom: 2px solid #f2f2f2;
        max-width: 20%;
        list-style: none;
        padding: 15px 10px;
    }

        .impot_flags li a img {
            width: 40px;
            height: 30px;
            border-radius: 5px;
            display: block;
            margin-right: 10px;
            box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        }

        .impot_flags li:nth-last-child(-n+4) {
            border-bottom: 0;
        }

        .impot_flags li a {
            display: flex;
            align-items: center;
        }


.faq{
    margin: 0;
    padding: 0;
}
.faq li{
    list-style: none;
    margin: 20px 0;
}
.faq li:first-child{
    margin-top: 0;
}
.faq li:last-child{
    margin-bottom: 0;
}
.faqclick{
    position: relative;
    display: block;
    cursor: pointer;
}
.faqclick i{
    position: absolute;
    right: 0;
    top: 8px;
    transition: .3s all;
}
.faqclick i.rotate{
    transition: .3s all;
    transform: rotate(180deg);
    
}
.faq_box{
    background: #fff;
    border-radius: 10px;
    box-shadow:  0 0 5px 0 #00000030;
    padding: 20px 40px;
    display: none;
    margin-top: 20px;
}

.faq li.active .faq_box{
    display: block;
}
.faq_box span{
    display: block;
    border-bottom: 1px dashed #E8E8E9;
    padding: 30px 10px;
    
}
.faq_box h5::before{
    content: '\f0a9';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    left: -35px;
    color: #1E43DF;
    font-size: 22px;
    position: absolute;

}
.faq_box span:first-child{
    padding-top: 0;
}
.faq_box span:last-child{
    padding-bottom: 0;
    border-bottom: none;
}
.faq_box h5{
    color: #1E43DF;
    position: relative;
    margin-bottom: 15px;
}

.faq_box p{
    margin: 0;
    color: #92949E;
    font-size:18px ;
    line-height: 28px;
}
.faq_box ul {
    margin: 0;
    padding: 0;
    margin-top: 10px;
    margin-left: 40px;
}
.faq_box ul li{
    list-style: none;
    padding:0 ;
    color: #92949E;
    font-size:18px ;
    line-height: 28px;
    margin:5px 0;
    position: relative;
}
.faq_box ul li::before{
    position: absolute;
    content: '\f0da';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    left: -15px;
}

footer{
    background: #0D0D10;
}
footer h4{
    color: #fff;
    font-weight: 600;
    font-size: 20px;
}
footer ul{
    padding: 0;
    margin: 0;
}
footer ul li{
    list-style: none;
    padding: 10px 0;
}
footer ul li a{
    color: rgba(255, 255, 255, .5);
    text-decoration: none;
    transition: .3s all;
    font-size: 15px;
}
footer ul li a:hover{
    transition: .3s all;
    color: #fff;
    text-decoration: none;
}
footer .social{
    margin-top: 20px;
}
footer .social ul{
    margin-top: 15px;
}
footer .social ul li{
    display: inline-block;
    padding: 0;
    margin: 0 5px;
}
footer .social ul li:first-child{
    margin-left: 0;
}
footer .social ul li:last-child{
    margin-right: 0;
}
footer .social ul li a{
    display: block;
    height: 35px;
    width: 35px;
    background: #fff;
    text-align: center;
    line-height: 35px;
    color: #313547;
    border-radius: 100px;
    font-size: 16px;
    transform: rotateY(0deg);
    transition: .9s all;
}
footer .social ul li a:hover{
    transform: rotateY(360deg);
    transition: .9s all;
}

footer p{
    color: rgba(255, 255, 255, .5);
    font-size: 15px;
    font-weight: normal;
    margin: 0;
}
footer p span{
   color: #fff;
}
footer .col-md-12 ul{
    margin-left: auto;
    margin-top: -1px;
}
footer .col-md-12 ul li{
    padding: 0;
    display: inline-block;
    margin: 0 15px;
}
footer .col-md-12 ul li:first-child{
    margin-left: 0;
}
footer .col-md-12 ul li:last-child{
    margin-right: 0;
}
.login_page{
    /* background: #fff; */
    height: 100vh;
    width: 100%;
}
.login_page .carousel{
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
}
.login_page .carousel-item{
    height: 100vh;
}
.login_page .carousel-item.slide1{
    background: #1E43DF;
}
.login_page .carousel-item.slide2{
    background: #FDBA28;
}
.login_page .carousel-item.slide3{
    background: #FE653C;
}
.login_page .carousel-item .det{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    color: #fff;
    padding: 0 50px;
}
.login_page .carousel-item .det .common_tagline{
    color: rgba(255, 255, 255, 0.60);
    font-size: 19px;
    line-height: 35px;
    margin-top: 30px;
}
.login_page .carousel-indicators li{
    height: 20px;
    cursor: pointer;
    width: 20px;
    border-radius: 50px;
    position: relative;
}
.login_page .carousel-indicators .active{
    background: rgba(255,255,255,.5);
}
.login_page .carousel-indicators li.active::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 13px;
    width: 13px;
    background: #fff;
    border-radius: 50px;
}
.login_height{
    height: 100vh;
    width: 100%;
    position: relative;
}
.login_sec{
    position: absolute;
    width: 100%;
    transform: translate(0, -50%);
    top: 50%;
    padding: 40px;
    background-color: #fff;
    transition: .3s all;
    box-shadow: 0 0 20px 0 #00000030;
    margin: 10px;
    
}
.login_sec .logo{
    display: inline-block;
    margin-bottom: 30px;
}
.login_sec .logo img{
    width: 200px;
}
.login_box{
    border-top: 2px dashed #ACBCFF;
    border-bottom: 2px dashed #ACBCFF;
    padding: 35px 0;
    padding-bottom: 25px;
    margin-top: 20px;
}
.form_group{
    position: relative;
}
.form_group .form_control{
    border-radius: 5px;
    border: 1px solid #707070;
    padding:15px 10px;
    font-size: 18px;
    color: #08080A;
}
.form_group .form_control:disabled, .form_group .form_control[readonly]{
    background: #fff;
    border-color: #707070;
    color: #707070;
}
.form_group .form_control:disabled ~ label, .form .form_control[readonly] ~ label{
    color: #707070;
}
.form_group label{
    color: #707070;
    font-size: 18px;
    /*position: absolute;
     top: 15px; 
    left: 25px;*/
    pointer-events: none;
    margin: 0;
    transition: .1s all;
    margin-top: 5px;
    margin-bottom: 5px;
}
.form_control::placeholder{
    color: rgba(0, 0, 0, 0.26);
    

}
.form_group .form_control:active, .form_group .form_control:focus{
    box-shadow: none;
    outline: 0;
    border-color: #1E43DF;
    color: #08080A;
}

.form_group .form_control:focus ~ label {
    top:-15px;
    background: #fff;
    padding: 0 5px;
    color:#1E43DF;
    transition: .1s all;
  }
  .form_group .value-exists{
    border-color: #1E43DF;
  }
.form_group .value-exists ~ label{
    top:-15px;
    background: #fff;
    padding: 0 5px;
    color:#1E43DF;
    transition: .1s all;
}
.form_btn{
    margin-left: auto;
    display: table;
    margin-top: 20px;
}
.form_btn a{
    color: #1E43DF;
    font-size: 18px;
    padding-right: 10px;
}
.form_btn a:hover, .form_botm a:hover{
    text-decoration: none;
    color: #1E43DF;
}

.form_btn button{
    padding:8px 12px;
}
.form_botm{
    color: #707070;
    text-align: center;
    font-size: 18px;
    margin: 0;
    margin-top: 20px;
}
.form_botm a{
    color: #1E43DF;
}
.mobile_field select{
    position: absolute;
    background: #1e43df;
    width: 259px;
    border: 1px solid #1e43df;
    border-top-left-radius: 5px;
    padding: 16px 5px;
    color: #fff;
    padding-right: 0;
    border-bottom-left-radius: 5px;
}
.mobile_field select:active, .mobile_field select:focus{
    box-shadow: none;
    outline: 0;
}
.mobile_field select option{
    background: #fff;
    color: #08080A;
}
.mobile_field .form_control{
    padding-left: 270px;
}
.mobile_field label{
    left: 270px;
}
.alert_mob {
    margin-left:145px;
}
@media(max-width:1030px) {
    .alert_mob {
        margin-left: 10px;
    }
}
.dash_tab {
    margin: auto;
    display: table;
}
.dash_tab ul{
    border: 1px solid #4467FE;
    border-radius: 50px;
    padding:5px 7px;
}
.dash_tab.top .nav-tabs .nav-link{
    border: none;
    font-weight: 600;
   padding:0;
    color: #08080A;
    font-size: 18px;
    transition: .3s all;
}
.dash_tab .nav-tabs .nav-link:hover{
    color: #4467FE;
    transition: .3s all;
    cursor: pointer;
}

.dash_tab .nav-tabs .nav-link{
    border: none;
    font-weight: 600;
    padding: 5px 30px;
    color: #08080A;
    font-size: 18px;
    transition: .3s all;
}
 .dash_tab .nav-tabs .nav-item.active .nav-link{
   color: #fff;
    transition: .3s all;
}
.dash_tab.top .nav-tabs .nav-item {
     position:relative;
    display:flex;
     padding: 5px 15px;
    align-items:center;
}

    .dash_tab .nav-tabs .nav-item.show .nav-link, .dash_tab .nav-tabs .nav-link.active {
        background: #4467FE;
        border-radius: 50px;
        color: #fff;
    }
    .dash_tab.top .nav-tabs .nav-item.active{
     background: #4467FE; 
     border-radius: 50px;
     color:#fff;
}
.dash_tab .nav-tabs .nav-item + .nav-item{
    padding-left:10px;
}
.year_dropdown {
    margin-left:auto;
    padding-left:5px;
    cursor:pointer;
}
    .year_dropdown ul {
        padding: 0;
        position: absolute;
        width: 140px;
        top: 80px;
        left: 80%;
        transform: translate(-50%, 0);
        margin-bottom: 0;
        text-align: center;
        border-radius: 0;
        border: 0;
        background: #fff;
        opacity: 0;
        pointer-events: none;
        box-shadow: 0 0 5px 0 #ddd;
        transition: .3s all;
    }
.year_dropdown ul.show{
    opacity:1;
    pointer-events:all;
    transition: .3s all;
    top:42px;
}
.year_dropdown ul li{
    list-style:none;
    
}
    .year_dropdown ul li a {
        color: #4467FE;
        padding: 10px;
        display: block;
        transition: .3s all;
    }
.year_dropdown ul li a:hover, .year_dropdown ul li.active a{
    background:#4467FE;
    color:#fff;
    text-decoration:none;
    transition:.3s all;
}
.year_dropdown ul li.active a{
    font-weight:600;
}
header.dashboard {
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
}
.user_tab{
    padding-right: 20px;
    position: relative;
    float:right;
    cursor: pointer;
}
.user_tab a.img{

    display:block;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    overflow: hidden;
    border:1px solid #08080A;
}
.user_tab button.img{
    display:block;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    overflow: hidden;
    border:1px solid #08080A;
}
.user_tab img{
    max-width: 100%;
    max-height: 100%;
}
/* .user_tab::after{
    content: '\f078';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    right: 0;
    top: 15px;
    transition: .3s all;

} */
.user_tab.rotate::after{
    transform: rotate(180deg);
    transition: .3s all;
}
.user_tabshow{
    position: absolute;
    width: 320px;
    background: #fff;
    right: 0;
    top: 70px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 #ddd;
    /* display: none; */
    z-index: 1;
}
.user_nameshow{
    display: flex;
    padding: 10px;
    /*background-image: url('images/side.jpg');*/
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    align-items: center;
}
.user_nameshow::after{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: #4467FE;
    opacity: .7;
    z-index: -1;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.user_nameshow a.img{
    margin-left: 0;
    border: none;
}
.user_namedes{
    color: #fff;
    margin-left: 20px;
}
.user_namedes h4{
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 15px;
}
.user_namedes p{
    margin: 0;
    font-size: 12px;
}
.user_namelog{
    background: #08080A;
    height: auto;
    width: auto;
    margin-left: auto;
    color: #fff;
    font-weight: 600;
    padding:2px 10px;
    border-radius: 50px;
    transition: .3s all
}
.user_namelog:hover{
    color:#08080A;
    background: #fff;
    transition: .3s all;
    text-decoration: none;
}
.user_tabshow ul{
    margin: 0;
    padding: 10px;
    text-align: left;
}
.user_tabshow ul li{
    list-style: none!important;
    padding: 5px 0;
    display: '';
}
.user_tabshow ul li a{
    color: #1E43DF;
    padding: 10px 10px;
    text-decoration: none;
}
.user_tabshow ul li a:hover{
    color: #08080A;
    text-decoration: none;
}
.user_nameshow .user_namelog img
{
    display: none;
}
.side_nav .user_namelog
{
background: none;
font-weight: normal;
}
.user_chatbtn ul{
    border-top: 1px solid #E8E8E9;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
}

.user_chatbtn ul li{
    flex:0 0 100%;
    max-width: 100%;
    padding:15px 20px;
    text-align: center;
    border-right: 1px solid #E8E8E9;
}
.user_chatbtn ul li:last-child{
    border-right: 0;
}
.user_chatbtn ul li a {
    font-weight: 600;
    display: block;
    transition: .3s all;
}
.user_chatbtn ul li a i{
    display: block;
    margin-bottom: 5px;
}
.user_chatbtn ul li:first-child a{
    color: #FE653C;
}
.user_chatbtn ul li:last-child a{
    color: #FDBA28;
}
.user_chatbtn ul li a:hover{
    color: #08080A;
    transition: .3s all;
}
.chat_box{
    position: fixed;
    top: 0;
    right: -500px;
    height: 100%;
    width: 350px;
    background: #fff;
    z-index: 9999;
    transition: .5s all;
}
.chat_box.show_chatbox{
    right: 0;
    transition: .5s all;
}
.chat_head{
    background: #4467FE;
    display: flex;
    padding: 25px 20px;
}
.chat_head h4{
    margin: 0;
    font-weight: 600;
    color: #fff;
}
.chat_msgbox{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding:15px 20px;
    background: #fff;
}
.chat_msgbox .form-group{
    margin: 0;
    position: relative;
}
.chat_msgbox .form-group .form-control{
    padding: 15px;
    border-radius: 50px;
    border: 1px solid #08080A;
    font-size: 18px;
    padding-right: 60px;

}
.chat_msgbox .form-group .form-control:active, .chat_msgbox .form-group .form-control:focus{
    border-color: #ACBCFF;
    box-shadow: none;
    outline: 0;
}
.chat_msgbox .form-group button{
    position: absolute;
    right: 8px;
    z-index: 9;
    top: 7px;
    background: #FE653C;
    border: 2px solid #FE653C;
    color: #fff;
    cursor: pointer;
    border-radius: 50px;
    height: 45px;
    width: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 25px;
    transition: .3s all;
}
.chat_msgbox .form-group button:hover{
    background: #fff;
    color: #FE653C;
    transition: .3s all;
}
.chat_msgbox .form-group button:active, .chat_msgbox .form-group button:focus{
    box-shadow: none;
    outline: 0;
}
.chat_msgs{
    height: 100vh;
    padding-bottom: 160px;
    overflow-y: scroll;
}
/* width */
.chat_msgs::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  .chat_msgs::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  .chat_msgs::-webkit-scrollbar-thumb {
    background: #ACBCFF; 
  }
  
  /* Handle on hover */
  .chat_msgs::-webkit-scrollbar-thumb:hover {
    background: #4467FE; 
  }
.chat_msgs ul{
    margin: 0 ;
    padding: 0;
}
.chat_msgs ul li{
    list-style: none;
    display: flex;
    align-items: center;
    padding: 10px 20px;
}
.chat_msgs ul li div{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    background: #fff;
    position: relative;
    border:1px solid #ACBCFF;
}
.chat_msgs ul li.user_chat div{
    margin-left: auto;
}
.chat_msgs ul li.client_chat p{
    margin-left: auto;
    border: 1px solid #4467FE;
    background: #fff;
    font-weight: 400;
}
.chat_msgs ul li.client_chat div{
    position: relative;
}
.chat_msgs ul li.client_chat div::after{
    position: absolute;
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50px;
    background: #1ED44E;
    bottom: 0;
    right: 0;
}
.chat_msgs ul li div img{
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    border-radius: 50px;
}
.chat_msgs ul li p{
    margin: 0;
    background: #ECF0FF;
    border: 1px solid #ECF0FF;
    padding:15px;
    border-radius: 50px;
    flex: 0 0 80%;
    max-width: 80%;
    color: #4467FE;
    font-weight: 500;
    box-shadow: 0 0 5px 0 #ddd;
    word-break: break-all;
}
.modal-backdrop{
    opacity: .6;
}
.side_nav{
    position: fixed;
    left: 0;
    top: 61px;
    background-image: url('./Usefull/images/side.jpg');
    background-size: cover;
    background-position: center;
    width: 230px;
    height: 100%;
    z-index: 1;
    transition: .5s all;
}
.side_nav::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity: .9;
    z-index: -1;
}
.side_nav ul{
    margin: 0;
    padding: 0;
}
.side_nav ul li{
    list-style: none;
    position: relative;
}
.side_nav ul li.active::after{
    content: '';
    position: absolute;
    height: 20px;
    width: 20px;
    background: #ECF0FF;
    transform: translate(0, -50%);
    right: -2px;
    top: 50%;
    z-index: 9;
    -webkit-clip-path: polygon(0 50%, 100% 100%, 100% 0);
clip-path: polygon(0 50%, 100% 100%, 100% 0);
}
.side_nav ul li a{
    padding: 12px;
    display: flex;
    color: #08080A;
    align-items: center;
    text-decoration: none;
}
.side_nav ul li a:hover{
    text-decoration: underline;
    color: #4467FE;
}
.side_nav ul li.active{
    background: #fff;
    box-shadow: 0 0 5px 0 #ddd;
}
.side_nav ul li a img{
    height: 30px;
}
.side_nav ul li a span{
    padding-left: 15px;
}
.dash_sec{
    padding: 30px 0;
    padding-left: 230px;
    padding-top: 85px;
}
.sec_block{
    box-shadow: 0 0 5px 0 #ddd;
    background: #fff;
    margin-bottom: 10px;
}
.sec_blockhead{
    display: flex;
    align-items: center;
    padding:15px 10px;
    border-bottom: 1px solid #E8E8E9;
}
.sec_blockhead h5{
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}
.sec_blockhead .user_namelog{
    font-size: 13px;
    padding: 1px 12px;
    border: 1px solid #08080A;
}
.sec_blockdet{
    display: flex;
    align-items: center;
    padding: 10px;
}
.sec_blockdet .btn_default{
    background: #4467FE;
    border-color: #4467FE;
    font-size: 14px;
    font-weight: 400;
    margin-left: auto;
    display: table;
    padding: 5px 8px;
}
.sec_blockdet .btn_default:hover{
    background: #fff;
    color: #4467FE;
}
.sec_block h4{
    color: #FE653C;
    font-weight: 600;
    font-size: 28px;
    margin: 0;
}
.sec_block p{
    color: #92949E;
    margin-top: 10px;
    margin-bottom: 0;
}
.sec_block p.validity{
    font-size: 12px;
    float: right;
}
.search_box{
    display: flex;
    padding-top: 15px;
}
.search_box .sub_btn{
    flex: 0 0 10%;
    max-width: 10%;
    padding: 0 10px;
}
.search_box .form_group{
    flex:  0 0 30%;
    padding: 0 10px;
    max-width: 30%;
}
.search_box.inner{
    flex-wrap: wrap;
}
.search_box.inner .form_group, .search_box.inner .sub_btn{
    flex: 0 0 16.66%;
    max-width: 16.66%;
}
.search_box.inner .sub_btn button{
    width: 100%;
}
.search_box .form_group .form_control{
    padding:8px 10px;
    height: auto !important;
}
.search_box .form_group label{
    top: 8px;
    left: 20px;
}
.search_box .form_group .value-exists ~ label{
    top: -15px;
}
.search_box .btn_default{
    padding:8px 10px;
}
.pro_form{
    padding: 10px;
    padding-top: 20px;
}
.pro_form .form_group{
    margin: 0;
}
.sec_table{
    width: 100%;
}
/* width */
.sec_table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  /* Track */
  .sec_table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .sec_table::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
  }
  
  /* Handle on hover */
  .sec_table::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.sec_table .table{
    margin: 0;
    width: 100%;
}
.sec_table .table td, .sec_table .table th{
    border-top: 0;
    text-align: center;
    vertical-align: middle;
}
.sec_table .table th{
    background: #ECF0FF;
    color: #4467FE;
    font-weight: 600;
    border-right: 1px solid #fff;
}
.sec_table .table tr th:last-child, .sec_table .table tr td:last-child{
    border-right: 0;
}
.sec_table .table tr:nth-child(even){
    background: #F9FAFF;
}
.sec_table .table td{
    border-right: 1px solid #F2F2F2;
    border-top:1px solid #F2F2F2;
    word-break:break-all;
}
.sec_table .table thead th{
    border-bottom: 0;
}
.sec_table tr td a{
    display: inline-block;
    height: 30px;
    width: 30px;
    background: #08080A;
    border: 1px solid #08080A;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    line-height: 30px;
    font-size: 13px;
    transition: .3s all;
}
.sec_table tr td a:hover{
    color: #08080A;
    background: #fff;
    transition: .3s all;
}
.help_float{
    position: fixed;
    right: 10px;
    z-index: 99;
    bottom: 10px;
}
.help_float a{
    height: 50px;
    width: 50px;
    text-align: center;
    font-size: 20px;
    line-height: 50px;
    padding: 0;
}
.modal_show .modal-content{
    border: none;
    border-radius: 5px;
}
.modal_show .modal-header{
    background: #4467FE;
    padding:15px 10px;
    color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
}
.modal_show .modal-header .close{
    padding: 0;
    margin: 0;
    height: 25px;
    width: 25px;
    background: #fff;
    opacity: 1;
    line-height: 25px;
    border-radius: 50px;
    transition: .3s all;
}
.modal_show .modal-header .close:hover{
    opacity: .6;
    transition: .3s all;
}
.modal_show .modal-header .close:active, .modal_show .modal-header .close:focus{
    box-shadow: none;
    outline: 0;
}
.modal_show .modal-body ul{
    margin: 0;
    padding: 0;
}
.modal_show .modal-body ul li{
    list-style: none;
    padding: 10px;
}
.modal_show .modal-body ul li+li{
    border-top: 1px solid #92949E;
}
.modal_show .modal-body ul li p{
    font-weight: 500;
    font-size: 18px;
    margin-bottom:5px;
}
.modal_show .modal-body ul li a{
    display: block;
    color: #1E43DF;
}
.modal_show .modal-body ul li a:hover{
    text-decoration: none;
}
.sec_table .table tr td.executive{
    color: #4467FE;
}
.sec_table .table tr td.paid i{
    background: #23B946;
    color: #fff;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
}
.sec_table .table tr td.business{
    color: #FDBA28;
}
.sec_table .table tr td.corporate{
    color: #FE653C;
}
.sec_table .table tr td.unpaid i{
    background: #ff2222;
    color: #fff;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
}
.back_overly{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: .5;
    z-index: 999;
}
.select_year .modal-header .close{
    padding: 0;
    margin: 0;
    height: 35px;
    width: 35px;
    background: #4467FE;
    opacity: 1;
    color: #fff;
    line-height: 25px;
    border-radius: 50px;
    transition: .3s all;
    margin-left: auto;
    display: block;
}
.select_year .modal-header .close:hover{
    opacity: .6;
    transition: .3s all;
}
.select_year .modal-header .close:active, .modal_show .modal-header .close:focus{
    box-shadow: none;
    outline: 0;
}
.select_year h3{
    text-align: center;
    padding: 20px;
}
.select_year .modal-header{
    border: 0;
}
.select_year ul{
    text-align: center;
    margin: 0;
    padding: 20px 0;
    padding-bottom: 40px;
}
.select_year ul li{
    list-style: none;
    display: inline-block;
}
.select_year ul li+li{
    padding-left: 10px;
}
.select_year ul li a{
    font-size: 28px;
    font-weight: 700;
    color: #E8E8E9;
    transition: .3s all;
}
.select_year ul li.active a{
    color: #4467FE;
    font-size: 34px;
    transition: 0s;
}
.select_year ul li a:hover{
    text-decoration: none;
    color: #4467FE;
    transition: .3s all;
}
.select_year ul li.active a:hover{
    transition: 0s;
}
.subsrecord{
    padding: 10px;
    border-bottom: 1px solid #E8E8E9;
}
.subsrecord h6{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}
.subsrecord h6 span{
    color: #4467FE;
}
.subsplan_dash{
    padding: 10px;
    padding-bottom: 20px;
}
.subsplan_dash .subscription_box h3{
    clip-path: none;
    padding: 20px 10px;
    font-size: 22px;
}
.subsplan_dash .recomm_plan span{
    bottom: -3px;
}
.subsplan_dash .col-lg-3:nth-child(2) .subscription_box h3{
    background: #4467FE;    
}
.subsplan_dash .col-lg-3:nth-child(3) .subscription_box h3{
    background: #FDBA28;
}
.subsplan_dash .col-lg-3:last-child .subscription_box h3 {
    background: #FE653C;
}
.subsplan_dash .subscription_box h4{
    color: #ACBCFF;
    font-size: 25px;
    font-weight: 900;
}
.subsplan_dash .subscription_box p{
    color: #ACBCFF;
    font-size: 21px;
}
.subsplan_dash .subscription_box ul li{
    font-size: 20px;
}
.subsplan_dash .col-lg-3:nth-child(2) .subscription_box h4, .subsplan_dash .col-lg-3:nth-child(2) .subscription_box p {
    color: #4467FE;
}
.subsplan_dash .col-lg-3:nth-child(3) .subscription_box h4 {
    color: #FDBA28;
    padding-top: 10px;
}
.subsplan_dash .col-lg-3:nth-child(3) .subscription_box p {
    color: #FDBA28;
}
.subsplan_dash .col-lg-3:last-child .subscription_box h4, .subsplan_dash .col-lg-3:last-child .subscription_box p {
    color: #FE653C;
}
.subsplan_dash .subscription_box.disabled{
    pointer-events: none;
}
.subsplan_dash .subscription_box.disabled h3{
    background: #ccc !important;
}
.subsplan_dash .subscription_box.disabled h4, .subsplan_dash .subscription_box.disabled p, .subsplan_dash .subscription_box.disabled ul li{
    color: #ccc !important;
}
.subsplan_dash .subscription_box.disabled .btn_default{
    border-color: #ccc;
    color: #ccc;
    background: #fff;
}
.plan_featdash{
    padding: 10px;
}
.plan_featbox{
    padding: 10px;
}
.plan_featbox h6{
    padding: 10px;
    background: #fff;
    color: #08080A;
}
.plan_featbox{
    color: #fff;
}
.plan_featbox ul{
    margin: 0;
    padding-top: 0;
}
.plan_featbox ul li{
    list-style: none;
    margin-left: 30px;
    line-height: 28px;
}
.plan_featdash .col-lg-4:nth-child(1) .plan_featbox{
    background: #FE653C;
}
.plan_featdash .col-lg-4:nth-child(2) .plan_featbox{
    background: #FDBA28;
}
.plan_featdash .col-lg-4:nth-child(3) .plan_featbox{
    background: #4467FE;
}
.paln_featnote p{
    color: #08080A;
    font-weight: 600;
}
.paln_featnote ul{
    margin: 0;
}
.paln_featnote ul li{
  list-style: none;
  padding: 5px 0;
  font-size: 13px;
  margin-left: 20px;
  color: #707070;
}
.pay_dets{
    padding: 10px;
}
.pay_dets h6{
    color: #4467FE;
    font-weight: 600;
}
.pay_dets p{
    color: #08080A;
    font-size: 15px;
}
.pay_dets p strong{
    font-weight: 600;
    display: block;
    margin-bottom: 10px;
}
.pay_dets p span{
    font-weight: 600;
}
.pay_dets p span a{
    font-weight: 400;
    color: #4467FE;
}
.pay_acdet ul{
    margin: 0;
}
.pay_acdet ul li{
    list-style: none;
    line-height: 28px;
}
.pay_acdet ul li span{
    font-weight: 600;
    width: 150px;
    display: inline-block;
}
.srch_tab{
    margin-left: 0;
}
.srch_tab ul{
    padding: 5px;
}
.srch_tab .nav-tabs .nav-link {
    border: none;
    font-weight: 500;
    padding: 5px 10px !important;
    color: #08080A;
    font-size: 15px;
}
.search_blockhead{
    margin-left: auto;
}
.search_blockhead a+a{
    margin-left: 10px;
}
.sec_table .table tr td label{
    cursor: pointer;
    margin: 0;
}
.sec_table .table tr td input[type=checkbox], .sec_table .table tr th input[type=checkbox], label.check_box input[type=checkbox] {
    height: 18px;
    width: 18px;
    border: 2px solid #4467fe;
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.sec_table .table tr td label span, .sec_table .table tr th label span, label.check_box span {
    height: 18px;
    width: 18px;
    border: 2px solid #4467fe;
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.sec_table .table tr td input[type=checkbox]:checked + span:before, .sec_table .table tr th input[type=checkbox]:checked + span:before, label.check_box input[type=checkbox]:checked + span:before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    top: -3px;
    left: 0px;
    font-size: 14px;
    color: #08080A;
}
.data_filter a{
    margin-left: auto;
    display: table;
    padding: 0;
    height: 35px;
    width: 40px;
    text-align: center;
    line-height: 35px;
    background: #FE653C;
    border-color: #FE653C;
    color: #fff;
}
.data_filter a:hover{
    background: #fff;
    color: #FE653C;
}
.filter_box{
    position: fixed;
    height: 100%;
    width: 420px;
    right:-500px;
    top: 0;
    z-index: 9999;
    background: #fff;
    transition: .3s all;
}
.showfilter_box{
    right: 0;
    transition: .3s all;
}
.inner_filter ul{
    margin: 0;
}
.inner_filter ul li{
    list-style: none;
    display: flex;
    padding: 5px 10px;
    align-items: center;
}
.inner_filter ul li span{
    margin-left: auto;
}
.inner_filter ul li p{
    margin: 0;
    padding-left: 10px;
}
.from_check{
    cursor: pointer;
    margin: 0;
}
.from_check input[type=checkbox] {
    display: none;
}
.from_check span{
    height: 18px;
    width: 18px;
    border: 2px solid #4467fe;
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-top: 4px;
}
.from_check input[type=checkbox]:checked + span:before {
    content: '\f00c';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    position: absolute;
    top: -3px;
    left: 0px;
    font-size: 14px;
    color: #08080A;
}
.inner_filter ul{
    height: 160px;
    overflow-y: auto;
}
/* width */
.inner_filter ul::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .inner_filter ul::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .inner_filter ul::-webkit-scrollbar-thumb {
    background: #FE653C;
  }
  
.inner_filter h5{
    margin: 0;
    font-weight: 600;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
.all_filter .inner_filter:first-child{
    border-top: 0;
}
.all_filter{
    
    padding-bottom: 10px;
    overflow-y:scroll; height:400px
}
/* width */
.all_filter::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .all_filter::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .all_filter::-webkit-scrollbar-thumb {
    background: #d4d4d4;
  }
  
  /* Handle on hover */
  .all_filter::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.show_filterdata{
    position: absolute;
    bottom: 0;
    left: -70px;
    background: #fff;
    color: #08080A;
    font-weight: 500;
    padding: 5px 15px;
}
.show_filterdata:hover{
    text-decoration: none;
    color: #fff;
    background: #08080A;
}

/*Responsive CSS*/

@media(max-width:1299px){
    .srchrslt_table .table thead {
        display: none;
    }

    .srchrslt_table .table tbody tr {
        display: block;
        margin-bottom: 20px;
        border-top: 2px solid #E8E8E9;
        border-bottom: 2px solid #E8E8E9;
    }

        .srchrslt_table .table tbody tr:first-child {
            border-top: 0;
        }

        .srchrslt_table .table tbody tr:last-child {
            border-bottom: 0;
        }

        .srchrslt_table .table tbody tr td {
            display: block;
            text-align: left;
            padding-left: 30%;
            position: relative;
        }

            .srchrslt_table .table tbody tr td::before {
                content: attr(data-label);
                position: absolute;
                left: 15px;
                font-weight: 600;
                color: #4467FE;
            }
}
@media(max-width:1199px){
    
    .subscription_box p{
        margin-bottom: 0;
    }
    .subscription_box ul li{
        padding: 5px;
    }
    .process li::before{
        right: -80px;
        width: 50%;
        background-size: 100%;
        top: 20px;
    }
    .process li:nth-child(2)::before{
        top: 0;
    }
    .process li:nth-child(3)::before {
       
        top: 100px;
        right: -30px;
    }
    .process li:nth-child(4)::before {
        top: -10px;
        right: -80px;
    }
    .process li:nth-child(5)::before {
        right: -80px;
        top: 10px;
    }
    .flag_box img{
        width: 170px;
        margin: auto;
    }
    .flag_det{
        padding: 10px;
    }
    footer .social ul li{
        margin: 0 2px;
    }
}

@media(max-width:1030px){
    .mobile_field select{
        width: 100px;
    }
    .mobile_field .form_control{
        padding-left: 105px;
    }
    .mobile_field label{
        left: 105px;
    }
}

@media(max-width:991px){
    .search_box.inner .form_group, .search_box.inner .sub_btn{
        flex: 0 0 33.33%;
        max-width: 33.33%;
    }
    .search_box .form_group{
        flex: 0 0 28%;
        max-width: 28%;
    }
    .search_box .sub_btn{
        flex: 0 0 16%;
        max-width: 16%;
    }
    .mobile_field select{
        width: 130px;
        padding: 17px 10px;
        padding-right: 0;
    }
    .mobile_field .form_control{
        padding-left: 140px;
    }
    .mobile_field label{
        left: 140px;
    }
    .subscription_sec .col-lg-3:nth-child(3) .subscription_box, .subscription_sec .col-lg-3:nth-child(4) .subscription_box{
        margin-top: 20px;
    }
    .subscription_sec .col-lg-3:nth-child(3) .subscription_box h3{
        padding-top: 20px;
    }
    .subscription_box{
        margin-top: 0;
    }
    .subscription_box:hover h3{
        padding-top: 20px;
    }
    .subscription_box .btn_default{
        margin-top: 20px;
    }
    .main_heading{
        font-size: 26px;
    }
    h2.common_heading{
        font-size: 20px;
    }
    .common_tagline{
        font-size: 16px;
    }
    .process li img{
        width: 80px;
    }
    .process li p{
        font-size: 18px;
    }
    .process li::before{
        right: -60px;
    }
    .process li:nth-child(4)::before {
        right: -60px;
    }
    .process li:nth-child(5)::before {
        right: -60px;
    }
    .pay_methbox span{
        height: 80px;
        width: 80px;
        line-height: 80px;
    }
    .pay_methbox span img{
        width: 60px;
    }
    .payment_method li:last-child .pay_methbox span img{
        width: 70px;
    }
    .pay_methbox p{
        font-size: 18px;
    }
    .payment_method li{
        flex: 0 0 33.3%;
        max-width:33.3%;
    }
    .payment_method li:nth-child(4) .pay_methbox::before, .payment_method li:last-child .pay_methbox::before{
        content: '';
        position: absolute;
        height: 100%;
        right: 0;
        top: 0;
        width: 2px;
        background: #ECF0FF;
    }
    .payment_method li:nth-child(5) .pay_methbox::after, .payment_method li:nth-child(6) .pay_methbox::after{
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 2px;
        background: #ECF0FF;
    }
    .payment_method li:nth-child(3) .pay_methbox::before, .payment_method li:nth-child(6) .pay_methbox::before{
        content: none;
    }
    .analys_rprt p{
        font-size: 18px;
    }
    .flag_box{
        display: block;
    }
    .flag_box img{
        width: 100%;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
    }
    .flag_table{
        height: 625px;
    }
    .flag_table table td, .flag_table table th{
        font-size: 15px;
    }
    h3.common_heading{
        font-size: 23px;
    }
    .faq_box h5{
        font-size: 18px;
    }
    .faq_box p, .faq_box ul li{
        font-size: 17px;
    }
    .faq_box h5::before{
        font-size: 20px;
    }
    footer .col-md-12 ul li{
        margin:  5px;
    }
    .topcontact li a, .toplogin li a{
        font-size:15px ;
    }
    body{
        font-size: 15px;
    }
    .payment_method li:hover .pay_methbox span::before{
        height: 100px;
        width: 100px;
    }
    .login_page .carousel-item .det{
        padding: 0 30px;
    }
}

@media(max-width:767px){
    .dash_sec .container{
        max-width: 100%;
    }.side_nav
    .invoice_table .table tr td{
        text-align: left;
        padding-left: 50%;
    }
    .invoice_table{
        height: auto;
    }
    .invoice_table table thead{
        display: none;
    }
    .invoice_table table tr{
        display: block;
        margin-bottom: 20px;
        border-bottom: 2px solid #F2F2F2;
        border-top: 2px solid #F2F2F2;
    }
    .invoice_table table tr:first-child{
        border-top: 0;
    }
    .invoice_table table tr:last-child{
        border-bottom: 0;
    }
    .invoice_table table tr td{
        display: block;
        position: relative;
    }
    .invoice_table table tr td::before{
        content: attr(data-label);
        position: absolute;
        left: 15px;
        font-weight: 600;
        color: #4467FE;
    
    }
    .search_box .btn_default{
        width: 100%;
    }
    .side_nav ul li.active::after{
        content: none;
    }
    .search_box .form_group{
        flex: 100%;
        max-width: 100%;
        margin-bottom: 10px;
    }
    .search_box .sub_btn{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .search_box{
        flex-wrap: wrap;
    }
    .mob_navtabdash {
        background: none;
        border: none;
        position: absolute;
        top: 11px;
        font-size: 30px;
    }
    /* .mob_navtabdash svg{
        display: block;
        height: 4px;
        width: 30px;
        border-radius: 100px;
        background: #08080A;
        margin: 5px 0;
        transition: .5s all;
        position: relative;
    } */
    .mob_navtabdash span{
        display: block;
        height: 4px;
        width: 30px;
        border-radius: 100px;
        background: #08080A;
        margin: 5px 0;
        transition: .5s all;
        position: relative;
    }
    .mob_navtabdash:active, .mob_navtabdash:focus{
        box-shadow: none;
        outline: none;
    }
    
    .mob_navtabdash.click span:nth-child(1){
        transform: rotate(222deg);
      top: 10px;
      transition: .5s all;
    }
    .mob_navtabdash.click span:nth-child(2){
        width: 0;
        transition: .1s all;
    }
    .mob_navtabdash.click span:nth-child(3){
        transform: rotate(-222deg);
        top: -8px;
        transition: .5s all;
    }
    .dash_sec{
        padding-left: 0;
    }
    .side_nav{
        left: -500px;
        top: 0;
        transition: .5s all;
    }
    .side_nav.show{
        left: 0px;
        
        transition: .5s all;
        z-index: 9999;
    }
    .side_nav .brand_logo{
        margin: auto;
    padding: 10px;
    width: 180px;
    }
    header.dashboard{
        padding: 10px 0;
    }
    .brand_logo{
        width: 170px;
    }
    .subscription_box h3, .subscription_box p{
        font-size:22px;
    }
    .subscription_box h4{
        font-size: 25px;
    }
    .recomm_plan span, .subscription_box ul li p, .subscription_box .btn_default{
        font-size: 18px;
    }
    .subscription_box ul li{
        font-size: 20px;
    }
    .process li img{
        width: 60px;
    }
    .process li::before, .process li:nth-child(5)::before{
        right: -45px;
        top: 10px;
    }
    .process li:nth-child(2)::before{
        top: -30px;
    }
    .process li:nth-child(3)::before {
        top: 65px;
        right: -45px;
    }
    .process li:nth-child(4)::before {
        right: -45px;
        top: -65px;
    }
    .pay_methbox p{
        font-size: 16px;
    }
    .analys_rprt p{
        margin-bottom: 20px;
    }
    .flag_box{
        display: flex;
    }
    .flag_box img{
        margin: 0;
        width: 200px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 5px;
    }
    .flag_table{
        padding: 0;
        height: auto;
    }
    .flag_table, .flag_table table{
        transform: none;
    }
    .flag_table table tr:last-child th, .flag_table table tr:last-child td{
        border-bottom: none;
    }
    .login_page .carousel-item .det{
        padding: 0 10px;
    }
    .login_sec{
        padding: 0 15px;
    }
    .dash_tab .nav-tabs .nav-link {
        padding: 2px 2px!important;
        font-size: 16px;
    }
    .dash_tab ul {
        padding:5px;
    }
}

@media(max-width:575px){
    .srch_tab ul{
        border: 0;
        padding: 0;
    }
    .srch_tab .pagination{
        display: inline-block;
    }
    .srch_tab .pagination li{
        display: flex;
        float: left;
    }
    .srch_tab ul li{
        padding: 5px 0;
    }
    .search_box.inner .form_group, .search_box.inner .sub_btn{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .user_tabshow{
        top: 55px;
    }
    
    .user_tab a.img{
        height: 35px;
        width: 35px;
    }
    .user_tab::after{
        top: 10px;
    }
    .svg-inline--fa fa-arrow-down{
    position: absolute;
    right: 0;
    top: 15px;
    }
    .topcontact li{
        padding: 5px;
    }
    .topcontact li a i{
        font-size: 20px;
        background: #fff;
        color: #0D0D10;
        border-radius: 50px;
        height: 35px;
        width: 35px;
        line-height: 35px;
        text-align: center;
    }
    .mob_navtab {
        position: absolute;
        right: 20px;
        background: none;
        border: none;
    }
    .mob_navtab span{
        display: block;
        height: 4px;
        width: 30px;
        border-radius: 100px;
        background: #08080A;
        margin: 5px 0;
        transition: .5s all;
        position: relative;
    }
    .mob_navtab:active, .mob_navtab:focus{
        box-shadow: none;
        outline: none;
    }
    
    .mob_navtab.click span:nth-child(1){
        transform: rotate(222deg);
      top: 10px;
      transition: .5s all;
    }
    .mob_navtab.click span:nth-child(2){
        width: 0;
        transition: .1s all;
    }
    .mob_navtab.click span:nth-child(3){
        transform: rotate(-222deg);
        top: -8px;
        transition: .5s all;
    }
    .mob_navshow{
        background: #fff;
    width: 100%;
    position: absolute;
    top: 115px;
    padding: 10px;
    z-index: 9;
    display: none;
    border-top: 1px dashed #ddd;
    }
    .mob_navshow .header_btn{
        text-align: center;
    }
    /* .subscription_box h4{
        padding-top: 0;
    } */
    .subscription_box{
        margin-top: 20px;
    }
    .subscription_sec .col-lg-3:nth-child(1) .subscription_box{
        margin-top: 0;
    }
    .process li{
        flex:  0 0 50%;
        max-width: 50%;
    }
    .process li:nth-child(1){
        order: 1;
    }
    .process li:nth-child(2){
        order: 2;
    }
    .process li:nth-child(3){
        order: 4;
    }
    .process li:nth-child(4){
        order: 3;
    }
    .process li:nth-child(5){
        order: 5;
    }
    .process li:nth-child(6){
        order: 6;
    }
    .process li::before, .process li:nth-child(5)::before {
        right: -65px;
        top: -20px;
    }
    .process li:nth-child(2)::before{
        transform: rotateX(180deg) rotate(270deg);
    right: -20px;
    top: 70px;
    }
    .process li:nth-child(4)::before{
        transform: rotateX(0deg) rotate(90deg);
    left: -20px;
    top: 40px;
    }
    .process li:nth-child(5)::before{
        transform: rotateX(-180deg) rotate(0deg);
    }
    .process li:nth-child(3)::before{
        transform: rotateX(0deg) rotate(180deg);
        left: -75px;
    top: 0;
    }
    .payment_method li{
        flex: 0 0 50%;
        max-width: 50%;
    }
    .payment_method li:nth-child(2) .pay_methbox::before, .payment_method li:nth-child(4) .pay_methbox::before, .payment_method li:nth-child(8) .pay_methbox::before{
        content: none;
    }
    .payment_method li:nth-child(3) .pay_methbox::before{
        content: '';
    }
    .login_page .carousel{
        position: relative;
        height: auto;
    }
    .login_page .carousel-item{
        height: 250px;
    }
    .login_page .col-lg-4.col-sm-5{
        padding: 0;
    }
    .login_page{
        height: auto;
    }
    .login_page .carousel-item .det .common_tagline{
        margin-top: 0;
        font-size: 16px;
        color: rgba(255, 255, 255, 0.70);
    }
    .login_sec{
        position: relative;
        transform: none;
        padding: 30px 15px;
        top: 0;
        margin: 0px;
    }
    .login_height{
        height: auto;
    }
}

@media(max-width:535px){
    .process li::before{
        content: none;
    }
    .flag_box img{
        width: 180px;
    }
}

@media(max-width:520px){
    /* .sec_blockhead{
        display: block;
    } */
    .flag_det{
        padding: 5px 10px;
    padding-right: 0;
    }
    .search_blockhead{
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    .search_blockhead a{
        margin-left: 0;
    }
    .srchrslt_table .table tbody tr td {
        padding-left: 45%;
        word-break: break-all;
    }
    .dash_tab .nav-tabs .nav-link {
        font-size: 14px;
    }
}
@media(max-width:500px){
    .flag_det{
        padding: 10px;
        padding-top: 20px;
    }
    .flag_box{
        display: block;
    }
    .flag_box img{
        width: 100%;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0px;
    }
    .faq_box{
        padding: 20px 10px;
    }
    .faq_box span{
        padding: 30px 0;
        padding-left: 20px;
    }
    .faq_box h5::before{
        font-size: 18px;
        left: -25px;
    }
}
@media(max-width:420px){
    .dash_tab .nav-tabs .nav-link {
        font-size:12px;
    }
    .show_filterdata {
        left: -67px;
    }
    .filter_box{
        width: 100%;
    }
    .search_box.inner .form_group, .search_box.inner .sub_btn{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .pay_acdet ul li span{
        width: 120px;
    }
    .chat_box{
        width: 100%;
    }
    .user_chatbtn ul li{
        padding: 15px 10px;
    }
    .user_tabshow{
        width: 290px;
    }
    .dash_top_nav{
        padding: 0 !important;
    }
    .user_tab{
        float: none;
        padding: 0;
    }
    .user_tab::after{
        content: none;
    }
   
    .mob_navtabdash span{
        width: 25px;
    }
    .mobile_field label{
        left: 115px;
    }
    .mobile_field .form_control{
        padding-left: 115px;
    }
    .login_page .carousel-item{
        height: 280px;
    }
    .mobile_field select{
        width: 110px;
    }
    .process li{
        flex: 0 0 100%;
        max-width: 100%;
        border-bottom: 1px dashed #d4d4d4;
        margin-bottom: 0;
        padding: 20px 0;
    }
    
    .process li:nth-child(3){
        order: 3;
    }
    .process li:nth-child(4){
        order: 4;
    }
    .process li:first-child{
        padding-top: 0;
    }
    .process li:last-child{
        border-bottom: 0;
        padding-bottom: 0;
    }
    header ul li a{
        padding: 25px 5px;
    }
   
}

@media(max-width:350px)
{
    .search_blockhead a+a{
        margin-left: 5px;
    }
    .pay_acdet ul li span{
        display: block;
        width: auto;
    }
    .mobile_field .value-exists ~ label{
        padding: 0 2px;
    }
    .mobile_field .form_control:active ~ label, .mobile_field .form_control:focus ~ label{
        padding: 0 2px;
    }
    .payment_method li{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .pay_methbox::before{
        content: none !important;
    }
    .payment_method li:nth-child(7) .pay_methbox::after{
        content: '';
    }
    .pay_methbox{
        padding: 15px 0;
    }
    .flag_table table td, .flag_table table th{
        font-size: 13px;
    }
    .topcontact li{
        padding:5px 1px;
    }
    header ul li a{
        padding: 25px 5px;
    }
}


/*06-12-2019*/
.table_column_short {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    height: 134px;
    width: 342px;
}

.table_date_width {
    width: 130px;
}

@media(max-width:1299px) {
    .table_date_width {
        width:100%;
    }
    .table_column_short {
        width:100%;
    }
    .srchrslt_table .table tbody tr td {
        display:-webkit-box;
    }
}
@media(max-width:991px) {
    .table_column_short {
        height:124px;
    }
}

/*07-12-2019*/
.subsrecord h6 span.paid i {
    background: #23B946;
    color: #fff;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
}

.subsrecord h6 span.unpaid i {
    background: #ff2222;
    color: #fff;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    border-radius: 50px;
    font-size: 13px;
}

.dropdown_height{
    height: 60px !important;
}
.form_group .value-date {
    border-color: #1E43DF;
}
.form_group .value-date  ~ label {
    top: -15px;
    background: #fff;
    padding: 0 5px;
    color: #1E43DF;
    transition: .1s all;
}
/*24-12-2019*/
.payment_block ul {
    margin: 0;
}
    .payment_block ul li {
        list-style: none;
        margin:5px 0;
    }


.table_tab .table tr:nth-child(odd) {
    background: #F9FAFF;
}

.table_tab .table tr th {
    background: #ECF0FF;
    color: #4467FE;
}

.table_tab .table thead th {
    border-bottom: 0;
}

.big_search {
    flex-wrap:wrap;

}
.big_search .form_group{
    flex:0 0 33.3%;
    max-width:33.3%;
}
.sec_detabocx {
    padding:10px;
}
.sec_detabocx ul {
    padding-left:30px;
    margin:0;
}
.sec_detabocx ul li {
    list-style:none;
}
.toobox_opt {
    display:flex;
    margin-left: auto;
}
.toobox_opt a {
    margin:0 3px;
    display: block;
    height:30px;
    width:30px;
    text-align:center;
    line-height:30px;
    border-radius: 50px;
}
.toobox_opt button {
    margin:0 3px;
    display: block;
    height:30px;
    width:30px;
    text-align:center;
    line-height:30px;
    border-radius: 50px;
}
.toolbox_ul {
    margin-bottom:0;
    margin-left: 0px;
    padding-left: 0px;
}
.toolbox_ul li {
    list-style: none;
    font-size: 14px;
    color: #92949E;
}
.toolbox_ul li+li{
    margin-top:5px;
}
.add_toolbox {
    font-size: 35px;
    display: block;
    height: 82px;
    background: #4467fe;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    line-height: 82px;
    opacity: .5;
    transition: .3s all;
    width: 82px;
}
.add_toolbox:hover{
    color:#fff;
    opacity:1;
    transition:.3s all;
}
.sec_commingSon {
    position:relative;
}
.sec_commingSon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #fff;
    opacity:.8;
    filter: blur(8px);
    -webkit-filter: blur(8px);
}
.sec_commingSon::after{
    content:'Comming Soon';
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    font-weight:600;
    font-size:32px;
 opacity:.4;
}
.filterbox
{
    padding: 10px;
    background-color: #fff;
    padding-bottom: 25px;
}
.filterbox .bactive
{
    text-transform: uppercase;
    font-weight: 600;
}
.filterbox ul
{
    margin: 0px;
    padding: 0px;

}
.filterbox ul li {
    float: left;
    text-decoration: none;
    list-style: none;
    margin: 0px 10px 0px 10px;
    font-size: 12px;
}
.linknor{color:#1e43df;transition:.3s ease-in-out;font-weight:500}.linknor:hover{color:#fdba28;text-decoration:none}

.dark_sec {
    background: rgb(8, 8, 10);
    padding: 30px 0;
}

    .dark_sec p {
        margin: 0;
        text-align: center;
        color: #fff;
    }

        .dark_sec p a {
            color: #FDBA28;
        }

.footer_extrlink ul {
    text-align: center;
    margin-bottom: 0;
}

    .footer_extrlink ul li {
        list-style: none;
        padding: 0 10px;
        display: inline-block;
    }

        .footer_extrlink ul li a {
            color: #666;
            font-size: 16px;
            transition: .3s ease-in-out;
        }

            .footer_extrlink ul li a:hover {
                color: #000;
                text-decoration: none;
            }
            .cleintsec {
                width: 100%;
                margin: 0px;
                background-color: #f3f3ff;
                /* background-color: #f7f7f7;*/
            }
            
            .cleintbox {
                width: 100%;
                padding: 0;
                margin-left: auto;
                margin-right: auto;
                /* box-shadow:0px 1px 10px rgba(51,51,51,0.2);*/
            }
                /*@media (min-width:992px)
            {
            .cleintbox  
             {
                 width:900px;
             }
            }*/
                .cleintbox .parhead {
                    color: #8b8989 !important;
                }
            
            .cleintlist {
                width: 100%;
                height: auto;
                padding: 0px;
                margin: 0px;
            }
            
                .cleintlist ul {
                    padding: 0px;
                    margin-bottom: 0px;
                    margin-left: 15px;
                    margin-right: 15px;
                    display: flex;
                    flex-wrap: wrap;
                    list-style: none;
                    transition: 0.3s all;
                }
            
                    .cleintlist ul li {
                        width: 100%;
                        text-align: center;
                        padding-left: 15px;
                        padding-right: 15px;
                        margin-bottom: 20px;
                        transition: 0.3s all;
                    }
            
                        .cleintlist ul li span {
                            display: block;
                            background-color: #fff;
                            padding: 20px 0px;
                        }
            
                            .cleintlist ul li span img {
                                max-width: 100%;
                            }
            
            @media (min-width:350px) {
                .cleintlist ul li {
                    flex: 0 0 50%;
                    max-width: 50%;
                    transition: 0.3s all;
                }
            
                    .cleintlist ul li:nth-last-child(-n+2) {
                        margin-bottom: 0;
                    }
            }
            
            @media (min-width:768px) {
                .cleintlist ul li {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                    transition: 0.3s all;
                }
            
                    .cleintlist ul li:nth-last-child(-n+3) {
                        margin-bottom: 0;
                    }
            }
            
            @media (min-width:992px) {
                .cleintlist ul li {
                    flex: 0 0 25%;
                    transition: 0.3s all;
                    max-width: 25%;
                }
            
                    .cleintlist ul li:nth-last-child(-n+4) {
                        margin-bottom: 0;
                    }
            }
            .headiman_data {
                width: 100%;
                padding: 0px;
                margin: 0px;
                margin-bottom: 20px;
            }
            
                .headiman_data h2 {
                    font-size: 26px;
                    line-height: 45px;
                    padding: 0px;
                    margin: 0px;
                    font-weight: 700;
                    text-align: center;
                }
            
                .headiman_data p {
                    padding: 0px;
                    color: #747477;
                    margin: 0px;
                    margin-left: 0px;
                    line-height: 26px;
                    margin-top: 0;
                    text-align: center;
                }
            
            @media (max-width:992px) {
                .headiman_data h2 {
                    font-size: 1.5em;
                    line-height: 32px;
                }
            }
            
            

            /* .accordion-collapse {
                border: 0;
            }
            .accordion-button {
                padding: 0px;
                font-weight: bold;
                border: 0;
                font-size: 18px;
                color: #333333;
                text-align: left;
                border-bottom: 1px solid #ddd; 
            }
            .accordion-button:focus {
                box-shadow: none;
                border: none;
            }
            .accordion-button:not(.collapsed) {
                background: none;
                color: #dc3545;
            }
            .accordion-body {
                padding: 15px;
                background-color: #f6f6f6;
            }
            .accordion-button::after {
                width: auto;
                height: auto;
                content: "+";
                font-size: 40px;
                background-image: none;
                font-weight: 100;
                color: #1b6ce5;
                transform: translateY(-4px);
            }
            .accordion-button:not(.collapsed)::after {
                width: auto;
                height: auto;
                background-image: none;
                content: "-";
                font-size: 48px;
                transform: translate(-5px, -4px);
                transform: rotate(0deg);
            } */

            .loading{
                background-color: red; height: 50px; position: absolute;
            }
            .user_tabshow_calender{
                position: absolute;
              
                background: #fff;
                right: 12px;
                top: 290px;
                border-radius: 5px;
                box-shadow: 0 0 5px 0 #ddd;
                /* display: none; */
                z-index: 1;
            }
            .pull-right
            {
                float: right !important;;
            }
            .side_navmobile .side_nav{
                left: 0px!important;
            }
            @media screen and (max-width: 40em){
                .responsiveTable tbody tr
                {
                    border: 0px !important;
                }
            }
.filterremove
{
    height: 18px;
    width: 18px;
    border: 2px solid #4467fe;
    display: inline-block;
    position: relative;
    cursor: pointer;
}
.btndatareadmore
{
    background-color: transparent;
    border: none;


}
.btndatareadmore svg
{
    
    width: 30px!important;
    height: 30px;
    color: #4467fe;
}
.filterbox li label{
    padding-right: 10px;

}
/* .filterbox li span{
    position: absolute;

} */
